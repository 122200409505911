<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'OptimizationMainPixels',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Current optimization',
          value: 'data.detailed_data.current_optimization'
        },
        {
          label: 'Past step optimization',
          value: 'settings.past_step_optimization'
        },
        {
          label: 'Main pixels',
          value: (alert) => {
            // we get main pixels as a string in json
            const pixelString = alert.settings.main_pixels.replaceAll('\'', '"')

            // map to just pixel ids
            // other info not really relevant, it's all { origin: dsp, type: pixel }
            const pixels = JSON.parse(pixelString).map(pixel => pixel.id)
            return `<br>- id: ${this.$commonUtils.htmlEntitiesList(pixels).join('<br>- id: ')}`
          }
        }
      ],
      settingsData: [
        {
          label: 'KPI',
          value: 'settings.KPI'
        }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
